<template>
  <b-row>
    <b-col cols="12" xl="4" class="nopadding">
      <b-card>
        <div class="text-center">
          <img v-if="doctor.logo" :src="computed_logo(doctor.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage" id="fileControl">
        </div>
        <br>
        <div class="row">
          <div class="col bg-secondary">
            <div class="row">
              <div class="col-8">
                <b-form-group>
                  <label for="txt_name">{{$t('common.fullname')}}</label>
                  <div v-if="$v.doctor.name.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.doctor.name.required">{{$t('common.fullname_required')}}</div>
                  </div>
                  <b-form-input type="text" id="txt_name" :placeholder="$t('common.placeholder_fullname')"
                  v-model.trim="doctor.name" maxlength="50" ref="txt_name" size="sm"
                  v-on:input="$v.doctor.name.$touch">
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group>
                  <label for="chk_show">{{$t('common.display')}}</label>
                  <b-form-checkbox switch size="lg" id="chk_show"
                  v-model="doctor.show"></b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="validation_dob">{{$t('common.dob')}}</label>
                  <div v-if="$v.doctor.dob.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.doctor.dob.required">{{$t('common.dob_required')}}</div>
                  </div>
                  <date-picker  class="date-picker-height" name="validation_dob" :config="options"
                  id="validation_dob"
                  v-model.trim="doctor.dob" :placeholder="$t('common.placeholder_dob')"></date-picker>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group>
                  <label for="ddlGender">{{$t('common.gender')}}</label>
                  <div v-if="$v.doctor.gender.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.doctor.gender.required">{{$t('common.gender_required')}}</div>
                  </div>
                  <b-form-select id="ddlGender" v-model.trim="doctor.gender"
                    v-on:input="$v.doctor.gender.$touch" size="sm">
                      <option value="null" disabled>{{$t('common.select_gender')}}</option>
                      <option v-for="d in ls_gender" :value="d.id"
                          v-bind:key="d._id">{{d.name[$i18n.locale]}}</option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="txt_phone">{{$t('common.phone')}}</label>
                  <div v-if="$v.doctor.phone.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.doctor.phone.required">{{$t('common.phone_required')}}</div>
                    <div class="text-danger" v-else-if="!$v.doctor.phone.numeric">{{$t('common.phone_number_only')}}</div>
                    <div class="text-danger" v-else-if="!$v.doctor.phone.minLength">{{$t('common.phone_ten_number')}}</div>
                    <div class="text-danger" v-else-if="!$v.doctor.phone.isUnique">{{$t('common.phone_existing')}}</div>
                  </div>
                  <b-form-input type="text" id="txt_phone"
                  v-model.trim="doctor.phone" maxlength="15" :disabled="doctor.id!=null"
                  v-on:input="$v.doctor.phone.$touch" size="sm"
                  :placeholder="$t('common.placeholder_phone')"></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group>
                  <label for="txt_email">Email</label>
                  <div v-if="$v.doctor.email.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.doctor.email.required">{{$t('common.email_required')}}</div>
                    <div class="text-danger" v-else-if="!$v.doctor.email.email">{{$t('common.email_invalid')}}</div>
                    <div class="text-danger" v-else-if="!$v.doctor.email.isUnique">{{$t('common.email_existing')}}</div>
                  </div>
                  <b-form-input type="text" id="txt_email"
                  v-model.trim="doctor.email" maxlength="50" :disabled="doctor._id!=null"
                  v-on:input="$v.doctor.email.$touch" size="sm"
                  :placeholder="$t('common.placeholder_email')"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="txt_specialized">Chuyên ngành</label>
                  <div v-if="$v.doctor.specialized.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.doctor.specialized.required">Nhập chuyên ngành.</div>
                  </div>
                  <b-form-input type="text" id="txt_specialized"
                  v-model.trim="doctor.specialized" maxlength="50"
                  v-on:input="$v.doctor.specialized.$touch" size="sm"
                  placeholder="Nhập chuyên ngành"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="txt_title">{{$t('common.title')}}</label>
                  <div v-if="$v.doctor.title.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.doctor.title.required">Nhập chức danh.</div>
                  </div>
                  <b-form-input type="text" maxlength="50" v-model.trim="doctor.title" id="txt_title" size="sm"
                  v-on:input="$v.doctor.title.$touch" placeholder="Nhập chức danh bác sỹ"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="txt_university">Trường/Cơ sở</label>
                  <div v-if="$v.doctor.university.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.doctor.university.required">Nhập tên trường/cơ sở.</div>
                  </div>
                  <b-form-input type="text" id="txt_university" size="sm"
                  v-model.trim="doctor.university" maxlength="100"
                  v-on:input="$v.doctor.university.$touch"
                  placeholder="Nhập tên trường/cơ sở"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="txt_hopital">Bệnh viện công tác</label>
                  <b-form-input type="text" id="txt_hopital" size="sm"
                  v-model.trim="doctor.hopital" maxlength="100"
                  placeholder="Nhập bệnh viện công tác"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="txtAddress">{{$t('common.address')}}</label>
                  <b-form-textarea type="text" id="txtAddress"
                  v-model="doctor.address" maxlength="200"
                  :placeholder="$t('common.placeholder_address')"></b-form-textarea>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <b-row class="row pull-right mt-2">
          <b-button variant="outline-info" class="mr-1" size="sm"
            @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}
          </b-button>
          <b-button variant="outline-success" @click.prevent="saveDoctor()" size="sm">
            <span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}}</span>
          </b-button>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="12" xl="8" class="nopadding">
      <transition name="slide">
      <b-card>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model.trim="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="name" slot-scope="data">
            <label>{{data.item.name}}</label>
          </template>
          <template slot="show" slot-scope="data">
            <label v-if="data.item.show">Có</label>
            <label v-else>Không</label>
          </template>
          <template slot="gender" slot-scope="data">
            <label v-if="data.item.gender">{{data.item.gender.name}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label v-if="data.item.created_at">{{VnDateTimeFormat(data.item.created_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getStatusText(data.item.status)}}</b-badge>
          </template>
          <template slot="in_phieu_kham" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-info"
                :disabled="data.item.status>0"
                @click="pdf(data.item)" v-b-modal.viewModal>
                <i class="fa fa-file-pdf-o"></i> In
              </b-button>
            </b-button-group>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)" v-b-tooltip.hover.left :title="$t('common.button.delete')">
                <i class="fa fa-trash"></i>
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)" v-b-tooltip.hover.left :title="$t('common.button.recover')">
                <i class="fa fa-recycle"></i>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
      </transition>
    </b-col>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
import pdfobj from 'pdfobject'
import { validationMixin } from 'vuelidate'
import { required,requiredIf, minLength,maxLength,url,numeric }
from 'vuelidate/lib/validators'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import { Role } from '../../helpers/role.js'
import { uuid } from 'vue-uuid'

export default {
  name: 'Doctors',
  mixins: [validationMixin],
  components: {Pager},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      processing:false,
      ls_gender: [],
      doctor:{
        id:null,
        show:true,
        name:null,
        email:null,
        phone:null,
        gender:null,
        dob:null,
        title:null,
        specialized:null,
        hopital:null,
        address:null,
        university:null,
        business:null
      },
      options: {
				format: 'DD-MM-YYYY',
				useCurrent: false,
				showClear: true,
        showClose: true
			}
    }
  },
  validations: {
    doctor: {
      name:{
        required,
        maxLength:maxLength(50)
      },
      university:{
        required,
        maxLength:maxLength(100)
      },
      dob:{
        required
      },
      email:{
        required,
        maxLength:maxLength(50),
        async isUnique(value) {
          if (value==null) return true
          if(this.doctor && this.doctor.id!=null) return true

          const res = await this.checkEmailUnique(value)
          return Boolean(res.data)
        }
      },
      phone:{
        required,
        numeric,
        maxLength:maxLength(15),
        maxLength:minLength(10),
        async isUnique(value) {
          if (value==null) return true
          if(this.doctor && this.doctor.id!=null) return true

          const res = await this.checkPhoneUnique(value)
          return Boolean(res.data)
        }
      },
      gender:{
        required
      },
      title:{
        required,
        maxLength:maxLength(50)
      },
      specialized:{
        required,
        maxLength:maxLength(50)
      }
    }
  },
  computed: {
    ...mapGetters('st_doctor', ['filter','page','per_page','page_count']),
    fields(){
      let fields=[]

      let user=this.$store.state.st_authentication.user
      if(user.role==Role.Super_Admin){
        fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
        fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
      }

      if(user.role==Role.Admin){
        fields.push({key: 'branch',label:this.$t('common.company_branch'),sortable: true, variant:'info'})
      }

      fields.push({key: 'name',label:this.$t('common.fullname'),sortable: true, variant:'primary'})
      fields.push({key: 'address',label:this.$t('common.email'),sortable: true})
      fields.push({key: 'phone',label:this.$t('common.phone'),sortable: true})
      fields.push({key: 'gender',label:this.$t('common.gender'),sortable: true})
      fields.push({key: 'dob',label:this.$t('common.dob'),sortable: true})
      fields.push({key: 'title',label:this.$t('common.title'),sortable: true})
      fields.push({key: 'specialized',label:this.$t('common.specialized'),sortable: true})
      fields.push({key: 'university',label:this.$t('common.university'),sortable: true})
      fields.push({key: 'hospital',label:this.$t('common.hospital'),sortable: true})
      fields.push({key: 'total',label:this.$t('common.phone'),sortable: true})
      fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
      fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    user_login(){
      return this.$store.state.st_authentication.user
    },
    filter:{
      get: function(){
        return this.$store.state.st_doctor.filter
      },
      set: function(text){
        this.$store.commit('st_doctor/set_filter',text)
      }
    },
    filteredAndSortedData:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_doctor.ls_doctor
        if (vm.filter) {
          result = result.filter(item =>vm.searchLike(item))
        }

        return result
      }
    }
  },
  methods: {
    ...mapActions('st_doctor', ['get_ls_doctor','apply_filter','change_page']),
    clear(){
      this.doctor={id:null,gender:null,address:null,
      hopital:null,business:null,title:null,university:null,show:false,business:null}
      this.$refs.txt_name.$el.focus()
      this.$v.$reset()
    },
    async checkEmailUnique(email){
      if(this.user_login==null) return false
      let param={email:email,company_id:this.user_login.company_id}
      return await this.$store.dispatch(`st_doctor/email_unique`,param)
    },
    async checkPhoneUnique(phone){
      if(this.user_login==null) return false
      let param={phone:phone,company_id:this.user_login.company_id}
      return await this.$store.dispatch(`st_doctor/phone_unique`,param)
    },
    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_doctor/recover_obj',obj.id)
      if(co && co.data.ok){
        this.get_ls_doctor()
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    async deleteObj(obj) {
      let app=this
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.client")}),app.$t("common.message"))
        return
      }
      let co=await app.$store.dispatch('st_doctor/delete_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_doctor()
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    confirmDelete(obj) {
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if(value!=true) return
        this.deleteObj(obj)
      })
      .catch(err => {
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      })
    },
    async isValid () {
      this.$v.$reset()
      this.$v.$touch()
      await this._validations()
      return Promise.resolve(!this.$v.$error)
    },
    async saveDoctor() {
      const isValid = await this.isValid()
      if (!isValid) {
        return
      }

      var app = this
      if (app.processing === true) {
        return
      }

      if(app.doctor.id){
        debugger
        app.doctor.user_update_id=app.user_login.id

        app.$store.dispatch('st_doctor/update',app.doctor)
        .then(function (resp) {
          app.get_ls_doctor()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        });
      }else{
        debugger
        app.doctor.company_id=app.user_login.company_id
        app.doctor.branch_id=app.user_login.branch_id
        app.doctor.user_create_id=app.user_login.id
        app.doctor.business=app.user_login.company.business

        app.$store.dispatch('st_doctor/create',app.doctor)
        .then(function (resp) {
          app.get_ls_doctor()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        });
      }
    },

    getRowCount (items) {
      return items.length
    },
    searchLike:function(item){
      return item.name.includes(this.filter)
      ||item.title&&item.title.includes(this.filter)
      ||item.university&&item.university.includes(this.filter)
      ||item.email.includes(this.filter)
      ||item.phone.includes(this.filter)
      ||item.business&&item.business.includes(this.filter)
      ||item.hopital&&item.hopital.includes(this.filter)
      ||item.address&&item.address.includes(this.filter)
      ||item.gender.name.includes(this.filter)
      ||item.company&&item.company.name.includes(this.filter)
    },
    rowClicked (item) {
      if(!item || item.status<0) return
      this.doctor=item
    },
    getImage:function(e){
      let app=this
      let image=e.target.files[0]
      if(image.size>102400){
        app.$bvModal.show("viewModal")
        document.getElementById("fileControl").value = ""
        return
      }
      let reader=new FileReader()
      reader.readAsDataURL(image)
      reader.onload=e=>{
        app.doctor.logo=e.target.result
      }
    }
  },
  mounted () {
    let vm=this
    vm.ls_gender=vm.option_gender
    vm.$refs.txt_name.$el.focus()
    vm.get_ls_doctor()
  }
}
</script>
